module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["GA-TRACKING_ID"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://fonts.gstatic.com"],"custom":[{"name":["Super Mario 256"],"file":"/fonts/mario/stylesheet.css"}],"web":[{"name":"Press Start 2P","file":"https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap"},{"name":"League Spartan","file":"https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;700&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cody Birthday 36","short_name":"Cody Birthday 36","start_url":"/","background_color":"#072e95","theme_color":"#072e95","display":"standalone","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"932e49f52373e5bdf5758d650ee60078"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
