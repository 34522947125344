import * as React from "react";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getFirestore, doc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocumentData } from "react-firebase-hooks/firestore";

import userDataContext from "./userDataContext";

const UserDataProvider = ({ children }) => {
  const auth = getAuth();

  const [user, authLoading, authError] = useAuthState(auth);
  // console.log("user:", user);
  // console.log("authLoading:", authLoading);

  const firestore = getFirestore();

  const document = user && user.uid && doc(firestore, "user-profile", user.uid);
  // console.log("document:", document);

  const [userProfile, userProfileLoading, userProfileError] =
    useDocumentData(document);

  // For some reason userProfileLoading is false for a little bit when the document first becomes non-null
  const profileLoadingLag =
    user && !userProfileLoading && !userProfile && !userProfileError;

  // console.log("userProfile:", userProfile);
  // console.log("userProfileLoading:", userProfileLoading);

  const ready =
    !authLoading &&
    !userProfileLoading &&
    !userProfileError &&
    !authError &&
    !profileLoadingLag;

  // console.log("ready:", ready);

  const userRsvped =
    userProfile &&
    userProfile.rsvpData &&
    userProfile.rsvpData.attendingStatus !== "UNKNOWN" &&
    userProfile.rsvpData.attendingStatus !== null &&
    userProfile.rsvpData.attendingStatus !== undefined;

  return (
    <userDataContext.Provider
      value={{
        user: user,
        userProfile: userProfile,

        authLoading: authLoading,
        authError: authError,

        userProfileLoading: userProfileLoading,
        userProfileError: userProfileError,

        loading: authLoading || userProfileLoading,
        ready: ready,

        userRsvped: userRsvped,
      }}
    >
      {children}
    </userDataContext.Provider>
  );
};

export default UserDataProvider;
