import React from "react";

import FirebaseContext from "./src/components/context/firebaseContext";

import "./src/styles/global.css";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import UserDataProvider from "./src/components/context/userDataProvider";
// import { errorHandler } from "./src/util/errors";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBIBuFfrM2TaFAxc6UZJeayPnVZEVs6VEQ",
  authDomain: "cody-birthday-thirtysix.firebaseapp.com",
  projectId: "cody-birthday-thirtysix",
  storageBucket: "cody-birthday-thirtysix.appspot.com",
  messagingSenderId: "261663258297",
  appId: "1:261663258297:web:96d81d8e29272877e68d68",
  measurementId: "G-NF1NME7FNK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// errorHandler.start({
//   key: "AIzaSyBIBuFfrM2TaFAxc6UZJeayPnVZEVs6VEQ",
//   projectId: "cody-birthday-thirtysix",
//   service: "cody-birthday-thirtysix",
//   version: "1.0.0",
// });

// Expose the Firebase app to React
export const wrapRootElement = ({ element }) => {
  return (
    <FirebaseContext.Provider value={app}>
      <UserDataProvider>{element}</UserDataProvider>
    </FirebaseContext.Provider>
  );
};
